<template>
  <div>
    <b-tabs
      pills
      :vertical="$store.getters['app/currentBreakPoint'] === 'xs'"
      content-class="col-12 px-sm-0"
      nav-wrapper-class="col-12 px-sm-0"
      nav-class="nav-left mb-sm-2"
    >
      <b-tab v-for="tab in tabs" :key="tab.key" :title="tab.name" lazy>
        <TableDynamic ref="refTableDynamic" :url="tab.url" :key-table="tab.key" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  components: {
    TableDynamic: () => import('./components/TableDynamicLocal.vue'),
  },
  data() {
    return {
      tabs: [
        {
          key: 'pending',
          name: 'รออนุมัติ',
          url: 'api/admin/recheck-zodiac/index/pending',
        },
        {
          key: 'checking',
          name: 'กำลังตรวจสอบ',
          url: 'api/admin/recheck-zodiac/index/checking',
        },
        {
          key: 'approved',
          name: 'อนุมัติ',
          url: 'api/admin/recheck-zodiac/index/approved',
        },
        {
          key: 'rejected',
          name: 'ไม่อนุมัติ',
          url: 'api/admin/recheck-zodiac/index/rejected',
        },
      ],
    }
  },
  methods: {
    reloadTable() {
      this.$refs.refTableDynamic[0].getData()
    },
  },
}
</script>

<style lang="scss" scoped></style>
